import React, { useEffect, useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import { border, breakpoints, linesLimit, color, gradient } from '../../utils/style';
import { linksInterpolate, isNan, isMobile } from '../../utils/system';
import CommentsStaticLoadMore from '../../components/Content/Withdraw/CommentsStaticLoadmore';

const WrapperDesktop = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  grid-auto-rows: auto;
  grid-row-gap: 40px;
  margin: 0;
  padding: 30px 0;
  height: max-content;

  @media (max-width: ${breakpoints.md}) {
    padding: 0;
  }

  @media (max-width: ${breakpoints.tl}) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
  }

  @media (max-width: ${breakpoints.xs}) {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    padding: 0;
  }
`;

const FeaturedWrapper = styled('div')`
  user-select: none;
  position: relative;
  div {
    border-radius: ${border.borderRadius4};
  }

  div {
    @media (max-height: ${breakpoints.md}) and (orientation: portrait) {
      height: 140px;
    }
  }

  .breadcrumbs {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 1;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  h1 {
    position: absolute;
    top: 40%;
    left: 30px;
    z-index: 1;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 44px;
    font-weight: bold;

    @media (max-width: ${breakpoints.md}) {
      font-size: 24px;
      font-weight: bold;
      color: ${color.lightningYellow};
      top: 40%;
      left: 40px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 20px;

    .breadcrumbs {
      top: 20px;
      left: 16px;
      height: max-content !important;

      & div {
        height: max-content !important;
      }
    }

    & h1 {
      left: 16px;
    }
  }
`;

const Item = styled('div')`
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  & img {
    border-radius: ${border.borderRadius4};
  }

  @media (max-width: ${breakpoints.md}) {
    & > a {
      & > div {
        margin-top: 10px;

        & > h3 {
          //font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          color: ${color.lightningYellow};
          margin-bottom: 10px;
        }

        & > div {
          //font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
          font-size: 13px;
          line-height: 1.62;
          color: ${color.alabaster};
        }
      }
    }
  }

  div > h3 {
    font-size: 19px;
    margin: 0 0 5px 0;

    @media (max-height: ${breakpoints.md}) {
      font-size: 18px;
    }

    a {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 19px;
      font-weight: 500;
      line-height: normal;
      color: ${color.lightningYellow};
      @media (max-height: ${breakpoints.md}) {
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 18px;
      }
    }
  }

  .gatsby-image-wrapper {
    min-height: 135px;
    margin-bottom: 5px;
  }

  div > div {
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    line-height: 1.57;
    color: ${color.alabaster};
    max-height: 176px;
    ${linesLimit(8)};

    :nth-of-type(2) {
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 80px;
      font-size: 13px;
      line-height: 1.62;
      color: ${color.alabaster};
    }
  }
`;

const PaginationWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${border.borderRadius4};
    border: solid 1.4px ${color.waikawaGray};
    cursor: pointer;
    user-select: none;
    margin-right: 16px;
    background-color: ${color.transparent};
    width: 36px;
    height: 36px;
    color: ${color.white};
    :active,
    :hover {
      background-color: ${color.lightningYellow} !important;
      border: none;
    }
  }
  a[aria-current] {
    background: ${gradient.roseBittersweet};
    border: none;
  }
`;

const PageTitle = styled('h1')`
  padding: 0;
  margin: 0;
`;

const Description = styled('span')``;

const CustomTmpWrapper = styled('div')`
  padding-bottom: 30px;
`;

const getPageNum = () => {
  let pageNum = 1;
  if (typeof window !== 'undefined') {
    const currPage = window.location.pathname.slice(0, -1);
    pageNum = +currPage.slice(currPage.lastIndexOf('/') + 1);
  }
  return pageNum;
};

// REFACTOR
// Spread / etc
const PaginatedPage = ({ data, pageContext }) => {
  const { group, pageCount, additionalContext } = pageContext;
  const checkIsCurrent = (edge, id) => edge.node.id === id;
  const pageNumber = pageContext.index;
  const currentCategoryArr = data.allWordpressCategory.edges.filter((edge) =>
    checkIsCurrent(edge, additionalContext.id));
  const currentCategory = currentCategoryArr[0].node;
  const metaTitle =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_title
      ? `${currentCategory.yoast_meta.yoast_wpseo_title} - Page ${pageNumber} ${process.env.SITE_NAME_SHORT}`
      : `${currentCategory.title} - Page ${pageNumber}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_metadesc
      ? currentCategory.yoast_meta.yoast_wpseo_metadesc
      : '';
  const { breadcrumbs } = pageContext;
  const [resolution, setResolution] = useState(false);
  const onResize = () => {
    setResolution(isMobile(500));
  };
  
  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  
  const banner = resolution ? currentCategory.acf?.category_mobile_image : currentCategory.acf?.category_image;
  const ref = useRef();

  const getPaginationElement = (page) => document.getElementById(`page-${page}`);
  useEffect(() => {
    if (window) {
      // The only way to update styles on pagination of button with number of pagination and nav menu
      const currPage = window.location.pathname.slice(0, -1);
      const currPageNum = +currPage.slice(currPage.lastIndexOf('/') + 1);

      const currSlug = currPage.slice(0, -2);
      const currNavEl = document.getElementById(currSlug.slice(1));

      const prevPage = getPaginationElement(ref.current);
      const nextPage = getPaginationElement(currPageNum);
      if (isNan(currPageNum)) {
        ref.current = 1;
        if (getPaginationElement(1)) {
          getPaginationElement(1).style.background = gradient.roseBittersweet;
          getPaginationElement(1).style.border = 'none';
        }
      }
      if (prevPage) {
        prevPage.style.background = '';
      }
      if (nextPage) {
        nextPage.style.background = gradient.roseBittersweet;
      }
      if (currNavEl) {
        currNavEl.style.borderBottom = '3px solid red';
      }
      if (currNavEl) {
        currNavEl.childNodes[currNavEl.childNodes.length - 1].style.color = 'red';
      }
      if (isNan(currPageNum)) {
        ref.current = 1;
      }
      ref.current = currPageNum;
    }
  }, []);

  const Pagination = ({ count }) => {
    const arr = new Array(count);
    arr.fill(null);
    return (
      <PaginationWrapper>
        {arr?.map((el, i) => {
          const realPage = i + 1;

          if (realPage === 1) {
            return (
              <Link
                aria-label={`page ${realPage}`}
                className="pagination"
                to={linksInterpolate`${currentCategory.slug}`}
                id={`page-${realPage}`}
              >
                {realPage}
              </Link>
            );
          }

          if (realPage === getPageNum()) {
            return (
              <Link
                aria-current={`page ${realPage}`}
                className="pagination"
                to={linksInterpolate`${currentCategory.slug}/${realPage}`}
                id={`page-${realPage}`}
              >
                {realPage}
              </Link>
            );
          }

          return (
            <Link
              aria-label={`page ${realPage}`}
              className="pagination"
              to={linksInterpolate`${currentCategory.slug}/${realPage}`}
              id={`page-${realPage}`}
            >
              {realPage}
            </Link>
          );
        })}
      </PaginationWrapper>
    );
  };

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} pathname={currentCategory.path} breadcrumbs={breadcrumbs} />
      {banner && (
        <FeaturedWrapper>
          <Img
            fluid={banner?.localFile.childImageSharp.fluid}
            alt={
              banner?.alt_text
                ? banner?.alt_text
                : currentCategory.name
            }
          />
          <Breadcrumbs breadcrumbs={breadcrumbs} page_title={currentCategory.name} />
          <PageTitle>{currentCategory.name}</PageTitle>
        </FeaturedWrapper>
      )}
      <WrapperDesktop>
        {group?.map(({ node }) => (
          <Item key={node.slug}>
            <Link to={linksInterpolate`${node.slug}`}>
              {node.featured_media &&
                node.featured_media.localFile &&
                node.featured_media.localFile.childImageSharp.fluid && (
                <Img
                  fluid={node.featured_media.localFile.childImageSharp.fluid}
                  alt={node.featured_media.alt_text ? node.featured_media.alt_text : node.title}
                />
              )}
              <div>
                <h3>
                  {node.title}
                </h3>
                <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
              </div>
            </Link>
          </Item>
        ))}
      </WrapperDesktop>

      {pageCount > 1 && <Pagination count={pageCount} />}

      {
        currentCategory.slug !== 'articles' && <CommentsStaticLoadMore />
      }

      {currentCategory.excerpt && <Description>{currentCategory.excerpt}</Description>}
    </Layout>
  );
};
export default PaginatedPage;

export const pageQuery = graphql`
  query($id: String) {
    allWordpressCategory(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          name
          slug
          path
          link
          acf {
            category_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1980) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            category_mobile_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 570) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fixed(width: 190, height: 190) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
